import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const Error404 = () => {
  return (
    <Container>
      <Row className='justify-content-md-center text-center mt-5'>
        <Col xs lg='6'>
          <h1>Pagina no encontrada</h1>
          <h3>Error 404</h3>
          <Link to='/'>Ir al inicio</Link>
        </Col>
      </Row>
    </Container>
  )
}
