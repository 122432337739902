import React, { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { ref, listAll, getDownloadURL, deleteObject } from 'firebase/storage'
import { addDoc, collection, Timestamp } from 'firebase/firestore'
import { db, storage, urlP } from '../firebase'
import { LoadingSpinner } from './LoadingSpinner'

export const SavePredictions = () => {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [end, setEnd] = useState(false)
  const [pasos, setPasos] = useState('INICIANDO CARGA')
  let filesDelete = []

  const int = async () => {
    setIsLoading(true)
    setPasos('PASO 1/6 LEYENDO PREDICCIONES')
    const stRef = ref(storage, `${urlP}/${id}`)
    await listAll(stRef)
      .then((resp) => resp.prefixes)
      .then(
        async (resp) =>
          await Promise.all(
            resp.map(async (resf) => {
              const stRef = ref(storage, `${urlP}/${resf._location.path_}`)
              return await listAll(stRef).then((res) =>
                res.prefixes.map((k) => k._location.path_)
              )
            })
          )
      )
      .then(async (resp) => {
        setPasos('PASO 2/6 LISTANDO ARCHIVOS')
        let filesList = []
        for (const res of resp) {
          for (const r of res) {
            const stRef = ref(storage, `${urlP}/${r}`)
            const list = await listAll(stRef)
            list.items.forEach((element) => {
              filesList = [...filesList, element._location.path_]
            })
          }
        }
        filesDelete = filesList
        return filesList
      })
      .then(async (resp) => {
        setPasos('PASO 3/6 LEYENDO ARCHIVOS')
        const urls = await Promise.all(
          resp.map(async (resf) => {
            return await getDownloadURL(ref(storage, `${urlP}/${resf}`))
          })
        )
        return urls
      })
      .then(async (urls) => {
        setPasos('PASO 4/6 LEYENDO DATA JSON')
        let jsonl = []
        for (const url of urls) {
          const cam = url.split('%2F')[1]
          await fetch(url)
            .then((response) => response.text())
            .then((resp) => {
              const arrayJSON = resp.split('\n')
              arrayJSON.forEach((json) => {
                let INDICES = []
                const JSOMLDATA = isJson(json)
                if (JSOMLDATA !== false) {
                  JSOMLDATA.prediction.confidences.forEach((it, d) => {
                    if (it >= 0.5) {
                      INDICES = [...INDICES, d]
                    }
                  })
                  const confidences = JSOMLDATA.prediction.confidences.filter(
                    (el, i) => INDICES.includes(i) && el
                  )
                  JSOMLDATA.prediction.confidences = confidences

                  const displayNames = JSOMLDATA.prediction.displayNames.filter(
                    (el, i) => INDICES.includes(i) && el
                  )
                  JSOMLDATA.prediction.displayNames = displayNames

                  const bboxes = JSOMLDATA.prediction.bboxes.filter(
                    (el, i) => INDICES.includes(i) && el
                  )
                  JSOMLDATA.prediction.bboxes = bboxes.map((el) => {
                    return { box: el }
                  })

                  const ids = JSOMLDATA.prediction.ids.filter(
                    (el, i) => INDICES.includes(i) && el
                  )
                  JSOMLDATA.prediction.ids = ids
                  JSOMLDATA.cam = cam

                  if (JSOMLDATA.prediction.confidences.length > 0) {
                    jsonl = [...jsonl, JSOMLDATA]
                  }
                }
              })
            })
            .catch(function (err) {
              console.log('Error', err)
            })
        }
        return jsonl
      })
      .then(async (jsonl) => {
        setPasos('PASO 5/6 CARGANDO DATA A LA DB')
        const expresion = /\d{14}/i
        for (const json of jsonl) {
          const fecha = json.instance.content.match(expresion)[0]
          const d = `${fecha.substring(0, 4)}-${fecha.substring(
            4,
            6
          )}-${fecha.substring(6, 8)}T${fecha.substring(
            8,
            10
          )}:${fecha.substring(10, 12)}:${fecha.substring(12, 14)}`
          const date = new Date(d)
          await addDoc(collection(db, id), {
            ...json,
            date: Timestamp.fromDate(date),
          })
        }
      })
      .then(async (del) => {
        setPasos('PASO 6/6 ELIMINANDO ARCHIVOS')
        await Promise.all(
          filesDelete.map(async (resf) => {
            const stRef = ref(storage, `${urlP}/${resf}`)
            return await deleteObject(stRef).catch((error) =>
              console.log('Error Delete', error)
            )
          })
        )
      })
      .then((fin) => {
        setPasos('FINALIZANDO CARGA')
        setTimeout(() => {
          setIsLoading(false)
          setEnd(true)
        }, 2000)
      })
  }

  const isJson = (str) => {
    try {
      return JSON.parse(str)
    } catch (e) {
      return false
    }
  }

  return (
    <Container>
      <Row className='justify-content-center text-center mt-2 mb-3'>
        <Col>
          <h4>Timelapse Vision</h4>
          <h6>{id}</h6>
          {isLoading ? (
            <>
              <LoadingSpinner />
              <p className='textPasos'>
                <small>{pasos}</small>
              </p>
            </>
          ) : !end ? (
            <Button onClick={() => int()} className='mt-5' variant='success'>
              INICIAR CARGA
            </Button>
          ) : (
            <Button as={Link} to={`/`} className='mt-5' variant='success'>
              CARGA FINALIZADA, IR AL INICIO
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  )
}
