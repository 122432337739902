import './styles/style.scss'
import { Principal } from './components/Principal'
import { Route, Routes } from 'react-router-dom'
import { Error404 } from './components/Error404'
import { SavePredictions } from './components/SavePredictions'
import { Chart } from './components/Chart'
import { FilterContext } from './context/FilterContext'
import { useEffect, useState } from 'react'
import { UserContext } from './context/UserContext'
import { auth } from './firebase'

function App() {
  const [filter, setFilter] = useState({})
  const [user, setUser] = useState('')
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user?.accessToken) {
        setUser(user?.accessToken)
      }
    })
  }, [])
  return (
    <UserContext.Provider value={{ user, setUser }}>
      <FilterContext.Provider value={{ filter, setFilter }}>
        <Routes>
          <Route path='/' element={<Principal />} />
          <Route path='/savepredictions/:id' element={<SavePredictions />} />
          <Route path='/chart/:id' element={<Chart />} />
          <Route path='*' element={<Error404 />} />
        </Routes>
      </FilterContext.Provider>
    </UserContext.Provider>
  )
}

export default App
