import { useContext, useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'
import { db } from '../firebase'
import { Container } from 'react-bootstrap'
import { FilterContext } from '../context/FilterContext'
import { meses, ordrByDate } from '../help/global'
import { ProgresBar } from './ProgresBar'
import { ChangeImagen } from './ChangeImagen'

export const ChartMod = ({ customer }) => {
  const [loading, setLoading] = useState(true)
  const [dataPre, setDataPre] = useState([])
  const [dataPreOld, setDataPreOld] = useState([])
  const [dataPro, setDataPro] = useState([])
  const [dataProOld, setDataProOld] = useState([])
  const [labelsPre, setLabelsPre] = useState([])
  const { filter } = useContext(FilterContext)
  const [textAxi, setTextAxi] = useState([])
  const [serie, setSerie] = useState([])
  const [serieOld, setSerieOld] = useState([])
  const date = new Date() // Suporner que es  2021-8-01

  useEffect(() => init(), [filter.btnC, filter.btnRF])
  useEffect(() => dataPre.length !== 0 && chegeLabers(), [dataPre])
  useEffect(() => dataPre.length !== 0 && changeDataOnChar(), [labelsPre])
  useEffect(() => dataPre.length !== 0 && changeCharOptions(), [dataPro])

  const changeCharOptions = async () => {
    // MES ANTERIOR
    let DATA = []
    switch (filter.btnRF.value) {
      case 3: {
        DATA = await changeYearActual()
        break
      }
      case 6: {
        DATA = await changeMesAnterior()
        break
      }
      case 7: {
        DATA = await changeYearPasado()
        break
      }
      case 8: {
        DATA = await changeAll()
        break
      }
      case 9: {
        DATA = await changeMesPerzo()
        break
      }
    }
    setSerie(DATA[0])
    setSerieOld(DATA[1])
    setLoading(false)
  }

  const changeAll = () => {
    let dataChar = dataPro
    const series = []
    textAxi.forEach((labTextX) => {
      const isExiste = dataChar.findIndex((elem) =>
        elem.fecha.includes(`${labTextX}/`)
      )
      if (isExiste === -1) {
        const fecha = `1/0/${labTextX}`
        const data = labelsPre.map((l) => {
          return { label: l, count: 0 }
        })
        dataChar.push({ fecha, data })
      }
    })
    dataChar = ordrByDate(dataChar)
    const dataYear = []
    textAxi.forEach((year, index) => {
      dataYear.push({
        fecha: year,
        data: labelsPre.map((l) => {
          return { label: l, count: 0 }
        }),
      })
    })
    dataChar.forEach((dat) => {
      const yearData = Number(dat.fecha.split('/')[2])
      const index = dataYear.findIndex((elem) => elem.fecha === yearData)
      const dataActual = dataYear[index].data
      const datos = []
      dataActual.forEach((d) => {
        dat.data.forEach((da) => {
          if (d.label === da.label) {
            const label = d.label
            const count = d.count + da.count
            datos.push({ label, count })
          }
        })
      })
      dataYear[index].data = datos
    })
    dataYear.forEach((el) => {
      el.data.forEach((elm) => {
        const lab = elm.label
        const val = elm.count
        const index = series.indexOf(series.find((v) => v.name === lab))
        if (index !== -1) {
          const d = {
            name: series[index].name,
            data: [...series[index].data, val],
          }
          series[index] = d
        } else {
          const d = { name: lab, data: [elm.count] }
          series.push(d)
        }
      })
    })
    return [series, series]
  }

  const changeYearPasado = () => {
    let dataChar = dataPro
    let dataCharOld = dataProOld
    const series = []
    const seriesOlde = []
    textAxi.forEach((labTextX) => {
      const mes = meses.indexOf(labTextX) + 1
      const year = date.getFullYear() - 1
      const isExiste = dataChar.findIndex((elem) =>
        elem.fecha.includes(`/${mes}/${year}`)
      )
      if (isExiste === -1) {
        const fecha = `1/${mes}/${year}`
        const data = labelsPre.map((l) => {
          return { label: l, count: 0 }
        })
        dataChar.push({ fecha, data })
      }
    })
    textAxi.forEach((labTextX) => {
      const mes = meses.indexOf(labTextX) + 1
      const year = date.getFullYear() - 1
      const isExiste = dataCharOld.findIndex((elem) =>
        elem.fecha.includes(`/${mes}/${year}`)
      )
      if (isExiste === -1) {
        const fecha = `1/${mes}/${year}`
        const data = labelsPre.map((l) => {
          return { label: l, count: 0 }
        })
        dataCharOld.push({ fecha, data })
      }
    })
    dataChar = ordrByDate(dataChar)
    dataCharOld = ordrByDate(dataCharOld)
    const dataYear = []
    const dataYearOld = []
    meses.forEach((mes, index) => {
      dataYear.push({
        fecha: mes,
        nmes: index,
        data: labelsPre.map((l) => {
          return { label: l, count: 0 }
        }),
      })
      dataYearOld.push({
        fecha: mes,
        nmes: index,
        data: labelsPre.map((l) => {
          return { label: l, count: 0 }
        }),
      })
    })
    dataChar.forEach((dat) => {
      const mesData = dat.fecha.split('/')[1]
      const index = dataYear.findIndex((elem) => elem.nmes === mesData - 1)
      const dataActual = dataYear[index].data
      const datos = []
      dataActual.forEach((d) => {
        dat.data.forEach((da) => {
          if (d.label === da.label) {
            const label = d.label
            const count = d.count + da.count
            datos.push({ label, count })
          }
        })
      })
      dataYear[index].data = datos
    })
    dataCharOld.forEach((dat) => {
      const mesData = dat.fecha.split('/')[1]
      const index = dataYearOld.findIndex((elem) => elem.nmes === mesData - 1)
      const dataActual = dataYearOld[index].data
      const datos = []
      dataActual.forEach((d) => {
        dat.data.forEach((da) => {
          if (d.label === da.label) {
            const label = d.label
            const count = d.count + da.count
            datos.push({ label, count })
          }
        })
      })
      dataYearOld[index].data = datos
    })
    dataYear.forEach((el) => {
      el.data.forEach((elm) => {
        const lab = elm.label
        const val = elm.count
        const index = series.indexOf(series.find((v) => v.name === lab))
        if (index !== -1) {
          const d = {
            name: series[index].name,
            data: [...series[index].data, val],
          }
          series[index] = d
        } else {
          const d = { name: lab, data: [elm.count] }
          series.push(d)
        }
      })
    })
    dataYearOld.forEach((el) => {
      el.data.forEach((elm) => {
        const lab = elm.label
        const val = elm.count
        const index = seriesOlde.indexOf(seriesOlde.find((v) => v.name === lab))
        if (index !== -1) {
          const d = {
            name: seriesOlde[index].name,
            data: [...seriesOlde[index].data, val],
          }
          seriesOlde[index] = d
        } else {
          const d = { name: lab, data: [elm.count] }
          seriesOlde.push(d)
        }
      })
    })
    return [series, seriesOlde]
  }

  const changeYearActual = () => {
    let dataChar = dataPro
    let dataCharOld = dataProOld
    const series = []
    const seriesOlde = []
    textAxi.forEach((labTextX) => {
      const mes = meses.indexOf(labTextX) + 1
      const isExiste = dataChar.findIndex((elem) =>
        elem.fecha.includes(`/${mes}/${date.getFullYear()}`)
      )
      if (isExiste === -1) {
        const fecha = `1/${mes}/${date.getFullYear()}`
        const data = labelsPre.map((l) => {
          return { label: l, count: 0 }
        })
        dataChar.push({ fecha, data })
      }
    })
    textAxi.forEach((labTextX) => {
      const mes = meses.indexOf(labTextX) + 1
      const isExiste = dataCharOld.findIndex((elem) =>
        elem.fecha.includes(`/${mes}/${date.getFullYear()}`)
      )
      if (isExiste === -1) {
        const fecha = `1/${mes}/${date.getFullYear()}`
        const data = labelsPre.map((l) => {
          return { label: l, count: 0 }
        })
        dataCharOld.push({ fecha, data })
      }
    })
    dataChar = ordrByDate(dataChar)
    dataCharOld = ordrByDate(dataCharOld)
    const dataYear = []
    const dataYearOld = []
    meses.forEach((mes, index) => {
      dataYear.push({
        fecha: mes,
        nmes: index,
        data: labelsPre.map((l) => {
          return { label: l, count: 0 }
        }),
      })
      dataYearOld.push({
        fecha: mes,
        nmes: index,
        data: labelsPre.map((l) => {
          return { label: l, count: 0 }
        }),
      })
    })
    dataChar.forEach((dat) => {
      const mesData = dat.fecha.split('/')[1]
      const index = dataYear.findIndex((elem) => elem.nmes === mesData - 1)
      const dataActual = dataYear[index].data
      const datos = []
      dataActual.forEach((d) => {
        dat.data.forEach((da) => {
          if (d.label === da.label) {
            const label = d.label
            const count = d.count + da.count
            datos.push({ label, count })
          }
        })
      })
      dataYear[index].data = datos
    })
    dataCharOld.forEach((dat) => {
      const mesData = dat.fecha.split('/')[1]
      const index = dataYearOld.findIndex((elem) => elem.nmes === mesData - 1)
      const dataActual = dataYearOld[index].data
      const datos = []
      dataActual.forEach((d) => {
        dat.data.forEach((da) => {
          if (d.label === da.label) {
            const label = d.label
            const count = d.count + da.count
            datos.push({ label, count })
          }
        })
      })
      dataYearOld[index].data = datos
    })
    dataYear.forEach((el) => {
      el.data.forEach((elm) => {
        const lab = elm.label
        const val = elm.count
        const index = series.indexOf(series.find((v) => v.name === lab))
        if (index !== -1) {
          const d = {
            name: series[index].name,
            data: [...series[index].data, val],
          }
          series[index] = d
        } else {
          const d = { name: lab, data: [elm.count] }
          series.push(d)
        }
      })
    })
    dataYearOld.forEach((el) => {
      el.data.forEach((elm) => {
        const lab = elm.label
        const val = elm.count
        const index = seriesOlde.indexOf(seriesOlde.find((v) => v.name === lab))
        if (index !== -1) {
          const d = {
            name: seriesOlde[index].name,
            data: [...seriesOlde[index].data, val],
          }
          seriesOlde[index] = d
        } else {
          const d = { name: lab, data: [elm.count] }
          seriesOlde.push(d)
        }
      })
    })
    return [series, seriesOlde]
  }

  const changeMesAnterior = () => {
    let dataChar = dataPro
    let dataCharOld = dataProOld
    const series = []
    const seriesOlde = []
    textAxi.forEach((labTextX) => {
      const f = new Date(date.getFullYear(), date.getMonth() - 1, labTextX)
      const isExiste = dataChar.findIndex(
        (elem) => elem.fecha === f.toLocaleDateString()
      )
      if (isExiste === -1) {
        const fecha = f.toLocaleDateString()
        const data = labelsPre.map((l) => {
          return { label: l, count: 0 }
        })
        dataChar.push({ fecha, data })
      }
    })
    textAxi.forEach((labTextX) => {
      const f = new Date(date.getFullYear(), date.getMonth() - 1, labTextX)
      const isExiste = dataCharOld.findIndex(
        (elem) => elem.fecha === f.toLocaleDateString()
      )
      if (isExiste === -1) {
        const fecha = f.toLocaleDateString()
        const data = labelsPre.map((l) => {
          return { label: l, count: 0 }
        })
        dataCharOld.push({ fecha, data })
      }
    })
    dataChar = ordrByDate(dataChar)
    dataCharOld = ordrByDate(dataCharOld)
    dataChar.forEach((el) => {
      el.data.forEach((elm) => {
        const lab = elm.label
        const val = elm.count
        const index = series.indexOf(series.find((v) => v.name === lab))
        if (index !== -1) {
          const d = {
            name: series[index].name,
            data: [...series[index].data, val],
          }
          series[index] = d
        } else {
          const d = { name: lab, data: [elm.count] }
          series.push(d)
        }
      })
    })
    dataCharOld.forEach((el) => {
      el.data.forEach((elm) => {
        const lab = elm.label
        const val = elm.count
        const index = seriesOlde.indexOf(seriesOlde.find((v) => v.name === lab))
        if (index !== -1) {
          const d = {
            name: seriesOlde[index].name,
            data: [...seriesOlde[index].data, val],
          }
          seriesOlde[index] = d
        } else {
          const d = { name: lab, data: [elm.count] }
          seriesOlde.push(d)
        }
      })
    })
    return [series, seriesOlde]
  }

  const changeMesPerzo = () => {
    const date = new Date(
      filter.btnRF.date.year,
      meses.indexOf(filter.btnRF.date.mes),
      1
    )
    let dataChar = dataPro
    let dataCharOld = dataProOld
    const series = []
    const seriesOlde = []
    textAxi.forEach((labTextX) => {
      const f = new Date(date.getFullYear(), date.getMonth(), labTextX)
      const isExiste = dataChar.findIndex(
        (elem) => elem.fecha === f.toLocaleDateString()
      )
      if (isExiste === -1) {
        const fecha = f.toLocaleDateString()
        const data = labelsPre.map((l) => {
          return { label: l, count: 0 }
        })
        dataChar.push({ fecha, data })
      }
    })
    textAxi.forEach((labTextX) => {
      const f = new Date(date.getFullYear(), date.getMonth() - 1, labTextX)
      const isExiste = dataCharOld.findIndex(
        (elem) => elem.fecha === f.toLocaleDateString()
      )
      if (isExiste === -1) {
        const fecha = f.toLocaleDateString()
        const data = labelsPre.map((l) => {
          return { label: l, count: 0 }
        })
        dataCharOld.push({ fecha, data })
      }
    })
    dataChar = ordrByDate(dataChar)
    dataCharOld = ordrByDate(dataCharOld)
    dataChar.forEach((el) => {
      el.data.forEach((elm) => {
        const lab = elm.label
        const val = elm.count
        const index = series.indexOf(series.find((v) => v.name === lab))
        if (index !== -1) {
          const d = {
            name: series[index].name,
            data: [...series[index].data, val],
          }
          series[index] = d
        } else {
          const d = { name: lab, data: [elm.count] }
          series.push(d)
        }
      })
    })
    dataCharOld.forEach((el) => {
      el.data.forEach((elm) => {
        const lab = elm.label
        const val = elm.count
        const index = seriesOlde.indexOf(seriesOlde.find((v) => v.name === lab))
        if (index !== -1) {
          const d = {
            name: seriesOlde[index].name,
            data: [...seriesOlde[index].data, val],
          }
          seriesOlde[index] = d
        } else {
          const d = { name: lab, data: [elm.count] }
          seriesOlde.push(d)
        }
      })
    })
    return [series, seriesOlde]
  }

  // TRAE LOS DATOS
  const init = async () => {
    setLoading(true)
    let predictions = []
    let predictionsOld = []
    let starDate = new Date()
    let endDate = new Date()
    switch (filter.btnRF.value) {
      case 3: {
        // AÑO ACTUAL
        const primerDia = new Date(date.getFullYear(), 0, 1)
        const ultimoDia = new Date(date.getFullYear() + 1, 0, 1)
        starDate = new Date(primerDia)
        endDate = new Date(ultimoDia)
        break
      }

      case 6: {
        // MES ANTERIOR
        const primerDia = new Date(date.getFullYear(), date.getMonth() - 1, 1)
        const ultimoDia = new Date(date.getFullYear(), date.getMonth(), 1)
        starDate = new Date(primerDia)
        endDate = new Date(ultimoDia)
        break
      }

      case 7: {
        // AÑO PASADO
        const primerDia = new Date(date.getFullYear() - 1, 0, 1)
        const ultimoDia = new Date(date.getFullYear(), 0, 0)
        starDate = new Date(primerDia)
        endDate = new Date(ultimoDia)
        break
      }

      case 8: {
        // DESDE EL INICIO
        const primerDia = new Date(0)
        const ultimoDia = new Date()
        starDate = new Date(primerDia)
        endDate = new Date(ultimoDia)
        break
      }

      case 9: {
        // PERSONALIZADO
        const primerDia = new Date(
          filter.btnRF.date.year,
          meses.indexOf(filter.btnRF.date.mes),
          1
        )
        const ultimoDia = new Date(
          filter.btnRF.date.year,
          meses.indexOf(filter.btnRF.date.mes) + 1,
          1
        )
        starDate = new Date(primerDia)
        endDate = new Date(ultimoDia)
        break
      }

      default:
        break
    }

    const resf =
      filter.btnC === 'Todos los Equipos'
        ? query(
            collection(db, customer),
            where('date', '>=', starDate),
            where('date', '<=', endDate),
            orderBy('date', 'asc')
          )
        : query(
            collection(db, customer),
            where('cam', '==', filter.btnC),
            where('date', '>=', starDate),
            where('date', '<=', endDate),
            orderBy('date', 'asc')
          )
    // Fechas periodo anterior
    switch (filter.btnRF.value) {
      case 3: {
        // AÑO ACTUAL
        const primerDia = new Date(date.getFullYear() - 1, 0, 1)
        const ultimoDia = new Date(date.getFullYear(), 0, 0)
        starDate = new Date(primerDia)
        endDate = new Date(ultimoDia)
        break
      }

      case 6: {
        // MES ANTERIOR
        const primerDia = new Date(date.getFullYear(), date.getMonth() - 2, 1)
        const ultimoDia = new Date(date.getFullYear(), date.getMonth() - 1, 1)
        starDate = new Date(primerDia)
        endDate = new Date(ultimoDia)
        break
      }

      case 7: {
        // AÑO PASADO
        const primerDia = new Date(date.getFullYear() - 2, 0, 1)
        const ultimoDia = new Date(date.getFullYear() - 1, 0, 0)
        starDate = new Date(primerDia)
        endDate = new Date(ultimoDia)
        break
      }

      case 8: {
        // DESDE EL INICIO
        const primerDia = new Date(0)
        const ultimoDia = new Date()
        starDate = new Date(primerDia)
        endDate = new Date(ultimoDia)
        break
      }

      case 9: {
        // PERSONALIZADO
        const primerDia = new Date(
          filter.btnRF.date.year,
          meses.indexOf(filter.btnRF.date.mes) - 1,
          1
        )
        const ultimoDia = new Date(
          filter.btnRF.date.year,
          meses.indexOf(filter.btnRF.date.mes),
          1
        )
        starDate = new Date(primerDia)
        endDate = new Date(ultimoDia)
        break
      }

      default:
        break
    }
    const resfOld =
      filter.btnC === 'Todos los Equipos'
        ? query(
            collection(db, customer),
            where('date', '>=', starDate),
            where('date', '<=', endDate),
            orderBy('date', 'asc')
          )
        : query(
            collection(db, customer),
            where('cam', '==', filter.btnC),
            where('date', '>=', starDate),
            where('date', '<=', endDate),
            orderBy('date', 'asc')
          )
    const data = await getDocs(resf)
    data.forEach((doc) => {
      const pre = { id: doc.id, ...doc.data() }
      predictions = [...predictions, pre]
    })
    const dataOld = await getDocs(resfOld)
    dataOld.forEach((doc) => {
      const pre = { id: doc.id, ...doc.data() }
      predictionsOld = [...predictionsOld, pre]
    })
    switch (filter.btnRF.value) {
      case 3: {
        setTextAxi(meses)
        break
      }
      case 6: {
        const dias = []
        const diasMes = new Date(date.getFullYear(), date.getMonth(), 0)
        for (let index = 0; index < diasMes.getDate(); index++) {
          dias.push(index + 1)
        }
        setTextAxi(dias)
        break
      }
      case 7: {
        setTextAxi(meses)
        break
      }
      case 8: {
        if (predictions.length > 0) {
          const y = predictions[0].date.toDate().getFullYear()
          const years = []
          for (let index = y; index < date.getFullYear() + 1; index++) {
            years.push(index)
          }
          setTextAxi(years)
        }
        break
      }

      case 9: {
        // PERSONALIZADO
        const dias = []
        const diasMes = new Date(
          filter.btnRF.date.year,
          meses.indexOf(filter.btnRF.date.mes) + 1,
          0
        )
        for (let index = 0; index < diasMes.getDate(); index++) {
          dias.push(index + 1)
        }
        setTextAxi(dias)
        break
      }

      default:
        break
    }
    if (predictions.length === 0) predictions.length = 1
    setDataPre(predictions)
    setDataPreOld(predictionsOld)
  }

  // DETERMINAR LOS LABELS
  const chegeLabers = () => {
    let labels = []
    dataPre.forEach((pre) => {
      labels = [...labels, ...pre.prediction.displayNames]
    })
    const LAB = labels.length > 0 ? labels : ['OBREROS', 'MAQUINAS']
    setLabelsPre([...new Set(LAB)])
  }

  // ORGANIZAR LOS DATOS Y DEPURAR
  const changeDataOnChar = () => {
    let dataOnChar = []
    let dataOnCharOld = []
    dataPre.forEach((el) => {
      const data = {}
      const datepre = el.date.toDate().toLocaleDateString()
      const existe = dataOnChar.find((el) => el.fecha === datepre)
      if (existe === undefined) data.fecha = datepre
      let datos = []
      labelsPre.forEach((lab) => {
        const count = el.prediction.displayNames.filter((el) => el === lab)
        const d = {
          label: lab,
          count: count.length,
        }
        if (existe !== undefined) {
          const vActual = existe.data.find((el) => el.label === lab)
          if (vActual.count > d.count) d.count = vActual.count
        }
        datos = [...datos, d]
      })
      data.data = datos
      if (existe === undefined) {
        dataOnChar = [...dataOnChar, data]
      } else {
        dataOnChar.forEach((el) => {
          if (el.fecha === datepre) el.data = data.data
        })
      }
    })
    dataPreOld.forEach((el) => {
      const data = {}
      const datepre = el.date.toDate().toLocaleDateString()
      const existe = dataOnCharOld.find((el) => el.fecha === datepre)
      if (existe === undefined) data.fecha = datepre
      let datos = []
      labelsPre.forEach((lab) => {
        const count = el.prediction.displayNames.filter((el) => el === lab)
        const d = {
          label: lab,
          count: count.length,
        }
        if (existe !== undefined) {
          const vActual = existe.data.find((el) => el.label === lab)
          if (vActual.count > d.count) d.count = vActual.count
        }
        datos = [...datos, d]
      })
      data.data = datos
      if (existe === undefined) {
        dataOnCharOld = [...dataOnCharOld, data]
      } else {
        dataOnCharOld.forEach((el) => {
          if (el.fecha === datepre) el.data = data.data
        })
      }
    })

    setDataProOld(dataOnCharOld)
    setDataPro(dataOnChar)
  }

  // OOPCIONES DEL GRAFICO
  const state = {
    options: {
      chart: {
        animations: {
          enabled: true,
          easing: 'linear',
          speed: 1000,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
        id: 'line',
      },
      stroke: {
        curve: 'smooth',
      },
      markers: {
        size: 6,
      },
      yaxis: {
        /* title: {
          text: 'Cantidad',
        }, */
      },
      xaxis: {
        /* 
        title: { text: titleX }, */
        categories: textAxi,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'letf',
        floating: false,
        offsetY: 0,
        offsetX: 0,
        formatter: function (seriesName, opts) {
          let count = 0
          let countOld = 0
          serie.forEach((s) => {
            if (s.name === seriesName) {
              s.data.forEach((d) => {
                count += d
              })
            }
          })
          serieOld.forEach((s) => {
            if (s.name === seriesName) {
              s.data.forEach((d) => {
                countOld += d
              })
            }
          })
          const difPorcentual =
            countOld !== 0 ? ((count - countOld) / countOld) * 100 : 0
          return difPorcentual !== 0
            ? `${seriesName}<br/>
          <div class='legend-info'>
            <span class='number'>${count}</span>
            <span class='porciento ${
              difPorcentual > 0 ? 'porciento-up' : 'porciento-dow'
            }'> 
            ${Math.abs(Math.round(difPorcentual))}
            </span>
          </div>`
            : `${seriesName}<br/><div class='legend-info'>
            <span class='number'>${count}</span>
            <span class='number'> --- </span>
            </div>`
        },
      },
    },
    series: serie,
  }

  // RENDER GRAFICO
  return (
    <>
      <ProgresBar loading={loading} />
      <Container className='containerChar p-0'>
        <Chart options={state.options} series={state.series} height={500} />
      </Container>
      <Container className='containerIMG p-0'>
        {dataPre.length > 1 && <ChangeImagen data={dataPre} />}
      </Container>
    </>
  )
}
