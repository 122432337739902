import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Container, ListGroup, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { ref, listAll } from 'firebase/storage'
import { storage, urlP } from '../firebase'
import { ChartMod } from './ChartMod'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { Filter } from './Filter'
import { FilterContext } from '../context/FilterContext'

export const Chart = () => {
  const { id } = useParams()
  const [listC, setListC] = useState([])
  const [btnC, setBtnC] = useState('Todos los Equipos')
  const [loadingEquipo, setLoadingEquipo] = useState(false)
  const [btnRF, setBtnRF] = useState({ value: 6, label: 'Mes Pasado' })
  const [loadingRF, setLoadingRF] = useState(false)
  const { filter, setFilter } = useContext(FilterContext)

  useEffect(() => int(), [])
  useEffect(() => setFilter({ btnRF, btnC }), [btnRF, btnC])

  const int = async () => {
    const stRef = ref(storage, `${urlP}/${id}`)
    const camarasList = await listAll(stRef).then((item) =>
      item.prefixes.map((cam) => cam._location.path_.split('/')[1])
    )
    setListC(['Todos los Equipos', ...camarasList])
  }

  const changeEquipo = (id) => {
    setBtnC(id)
    setLoadingEquipo(!loadingEquipo)
  }
  const changeRF = (data) => {
    setBtnRF(data)
    setLoadingRF(!loadingRF)
  }

  return (
    <Container>
      <Row className='justify-content-center text-center mt-2 mb-3'>
        <Col>
          <h4>Timelapse Vision</h4>
        </Col>
      </Row>
      <Row className='justify-content-center d-flex align-items-center'>
        <Col xs={12} lg={4}>
          <div className='d-grid gap-2'>
            <Button
              variant='light'
              size='sm'
              onClick={() => setLoadingEquipo(!loadingEquipo)}
              className='d-flex justify-content-between align-items-center'>
              {btnC}
              <FontAwesomeIcon icon={faAngleDown} />
            </Button>
            <div className='menuSelect'>
              {loadingEquipo && (
                <ListGroup className='content' variant='flush'>
                  {listC.map((item) => (
                    <ListGroup.Item
                      action
                      onClick={() => changeEquipo(item)}
                      key={`${item}`}>
                      {item}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </div>
          </div>
        </Col>
        <Col xs={12} lg={4}>
          <div className='d-grid gap-2'>
            <Button
              variant='light'
              size='sm'
              onClick={() => setLoadingRF(!loadingRF)}
              className='d-flex justify-content-between align-items-center'>
              {btnRF.label}
              <FontAwesomeIcon icon={faAngleDown} />
            </Button>
            <div className='menuSelect'>
              {loadingRF && <Filter changeRF={changeRF} />}
            </div>
          </div>
        </Col>
      </Row>
      <Row className='justify-content-center text-center mt-2'>
        <Col>
          {Object.keys(filter).length > 0 && <ChartMod customer={id} />}
        </Col>
      </Row>
    </Container>
  )
}
