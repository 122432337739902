import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { GoogleAuthProvider, getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyBRlRJzRhy1G88q4ekqOt_zLXyQkyL2on8',
  authDomain: 'vision-7030f.firebaseapp.com',
  projectId: 'vision-7030f',
  storageBucket: 'vision-7030f.appspot.com',
  messagingSenderId: '235387155201',
  appId: '1:235387155201:web:ad9bac3a0cda17790ca100',
  measurementId: 'G-8775NDDGVF',
}
const app = initializeApp(firebaseConfig)
export const urlP = 'gs://timelapse_vision'
export const storage = getStorage(app)
export const auth = getAuth()
export const providerGoogle = new GoogleAuthProvider()
export const db = getFirestore(app)
