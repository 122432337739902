export const colors = [
  '252, 44, 0',
  '252, 229, 0',
  '36, 252, 0',
  '0, 252, 246',
  '0, 29, 252',
  '234, 0, 252',
  '103, 123, 225',
  '103, 225, 112',
  '225, 134, 103',
  '222, 225, 103',
]
export const meses = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Deciembre',
]

export const listFilter = [
  'Año Actual',
  'Mes Actual',
  'Semana Actual',
  'Hoy',
  'Ayer',
  'Semana Pasada',
  'Mes Pasado',
  'Año Pasado',
]

export const convierteFecha = (fecha) => {
  const f = fecha.split('/')
  return `${f[2]}/${f[1]}/${f[0]}`
}

export const ordrByDate = (arrayData) => {
  return arrayData.sort((a, b) => {
    const AD = a.fecha.split('/')
    const BD = b.fecha.split('/')
    const FA = new Date(AD[2], AD[1], AD[0])
    const FB = new Date(BD[2], BD[1], BD[0])
    if (FA > FB) {
      return 1
    }
    if (FA < FB) {
      return -1
    }
    return 0
  })
}
