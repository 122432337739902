import React from 'react'

export const ProgresBar = ({ loading }) => {
  return (
    <div className={loading ? 'visible' : 'hiden'}>
      <div className='progress-bar'>
        <div className='progress-bar-value'></div>
      </div>
    </div>
  )
}
