import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { meses } from '../help/global'

export const Filter = ({ changeRF }) => {
  const date = new Date()
  const [init, setInit] = useState(false)
  const [year, setYear] = useState(date.getFullYear())

  return (
    <>
      {!init ? (
        <Container className='content py-2 shadow'>
          <Row>
            <Col>
              <div className='d-grid gap-2'>
                <Button
                  variant='light'
                  size='sm'
                  disabled
                  onClick={() => changeRF({ value: 0, label: 'Hoy' })}>
                  Hoy
                </Button>
                <Button
                  variant='light'
                  size='sm'
                  disabled
                  onClick={() =>
                    changeRF({ value: 1, label: 'Semana actual' })
                  }>
                  Semana actual
                </Button>
                <Button
                  variant='light'
                  size='sm'
                  disabled
                  onClick={() => changeRF({ value: 2, label: 'Mes actual' })}>
                  Mes actual
                </Button>
                <Button
                  variant='light'
                  size='sm'
                  onClick={() => changeRF({ value: 3, label: 'Año actual' })}>
                  Año actual
                </Button>
              </div>
            </Col>
            <Col>
              <div className='d-grid gap-2'>
                <Button
                  variant='light'
                  size='sm'
                  disabled
                  onClick={() => changeRF({ value: 4, label: 'Ayer' })}>
                  Ayer
                </Button>
                <Button
                  variant='light'
                  size='sm'
                  disabled
                  onClick={() =>
                    changeRF({ value: 5, label: 'Semana pasada' })
                  }>
                  Semana pasada
                </Button>
                <Button
                  variant='light'
                  size='sm'
                  onClick={() => changeRF({ value: 6, label: 'Mes pasado' })}>
                  Mes pasado
                </Button>
                <Button
                  variant='light'
                  size='sm'
                  onClick={() => changeRF({ value: 7, label: 'Año pasado' })}>
                  Año pasado
                </Button>
              </div>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <div className='d-grid gap-2'>
                <Button
                  variant='light'
                  size='sm'
                  onClick={() =>
                    changeRF({ value: 8, label: 'Desde el inicio' })
                  }>
                  Desde el inicio
                </Button>
                <Button variant='light' size='sm' onClick={() => setInit(true)}>
                  Perzonalizado
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container className='content py-2 shadow'>
          <Row className='mt-2'>
            <Col>
              <div className='d-grid gap-2'>
                <Button
                  variant='light'
                  size='sm'
                  onClick={() => setYear(year - 1)}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Button>
              </div>
            </Col>
            <Col>
              <div className='d-grid gap-2 text-center'>{year}</div>
            </Col>
            <Col>
              <div className='d-grid gap-2'>
                <Button
                  variant='light'
                  size='sm'
                  disabled={year >= date.getFullYear()}
                  onClick={() => setYear(year + 1)}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </div>
            </Col>
          </Row>
          <Row className='mt-2'>
            {meses.map((mes) => (
              <Col xs={12} md={4} lg={3} key={mes}>
                <div className='d-grid gap-2 mb-2'>
                  <Button
                    variant='light'
                    size='sm'
                    disabled={
                      year >= date.getFullYear() &&
                      meses.indexOf(mes) >= date.getMonth()
                    }
                    onClick={() =>
                      changeRF({
                        value: 9,
                        label: `Per: ${mes}-${year}`,
                        date: { mes, year },
                      })
                    }>
                    {mes}
                  </Button>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      )}
    </>
  )
}
