import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { auth, providerGoogle, storage, urlP } from '../firebase'
import { listAll, ref } from 'firebase/storage'
import { UserContext } from '../context/UserContext'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'

export const FormInitial = () => {
  const { user, setUser } = useContext(UserContext)
  const [id, setId] = useState('')
  const [options, setOptions] = useState([])
  useEffect(() => int(), [user])

  const googleLogin = () => {
    user === '' && loginGoogle()
  }
  const loginGoogle = () => {
    signInWithPopup(auth, providerGoogle)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result)
        /* result._tokenResponse.isNewUser
          ? deleteUser(auth.currentUser).catch((error) => console.log(error))
          : */ setUser(credential.accessToken)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const int = async () => {
    const stRef = ref(storage, `${urlP}/${id}`)
    await listAll(stRef)
      .then((resp) => resp.prefixes)
      .then((resp) => {
        const array = []
        resp.forEach((name) => {
          array.push(name._location.path_)
        })
        setOptions(array)
      })
  }
  return (
    <Container>
      <Row className='justify-content-md-center text-center mt-5'>
        <Col xs lg='6'>
          <h1>Timelapse Vision</h1>
          {user !== '' && (
            <>
              <Form.Label className='pt-5'>Su Id Cliente</Form.Label>
              <Form.Select
                className='mb-3'
                onChange={(event) => setId(event?.target?.value)}>
                <option>Seleccione el cliente</option>
                {options.map((el) => (
                  <option key={el} value={el}>
                    {el}
                  </option>
                ))}
              </Form.Select>
              <div className='d-grid gap-2'>
                <Button
                  as={Link}
                  to={id !== '' && `/savepredictions/${id}`}
                  variant='primary'>
                  Navegar a mis datos
                </Button>
              </div>
            </>
          )}
          <div className='d-grid gap-2 mt-5'>
            {user === '' && (
              <Button
                variant='primary'
                type='submit'
                className='float-end'
                onClick={googleLogin}>
                Iniciar Sesion
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  )
}
