import { useEffect, useState } from 'react'
import { Button, Container, Image, Modal } from 'react-bootstrap'

export const ChangeImagen = ({ data }) => {
  const [showData, setShowData] = useState([])
  const [page, setPage] = useState(0)
  const [show, setShow] = useState(false)
  const [urlActive, setUrlActive] = useState('')
  const pages = Math.ceil(data.length / 6)
  const handleClose = () => setShow(false)
  const handleShow = (URL) => {
    setUrlActive(URL)
    setShow(true)
  }

  useEffect(() => {
    const star = page * 6
    const d = []
    for (let index = star; index < star + 6; index++) {
      console.log(index)
      d.push(data[index])
    }
    setShowData(d)
  }, [page])

  console.log(data)

  return (
    <>
      {data.length > 0 &&
        showData.map((d) => {
          const cam = d.cam.split('_')
          const idCam = cam[cam.length - 1]
          const img = d.instance.content.split('/')
          const imgName = img[img.length - 1]
          const URL = `http://cloud.timelapse.cl/cam/${idCam}/${imgName}`
          return (
            <Image
              key={imgName + Math.random()}
              src={URL}
              alt={URL}
              onClick={() => handleShow(URL)}
              width={200}
              height={200}
              className='img-change'
              role='button'
            />
          )
        })}
      <Container className='mb-4 d-flex justify-content-evenly'>
        <Button
          variant='primary'
          onClick={() => setPage(page - 1)}
          disabled={page === 0}>
          Anterior
        </Button>
        <div>
          Pagina {page}/{pages}
        </div>
        <Button
          variant='primary'
          onClick={() => setPage(page + 1)}
          disabled={page === pages}>
          Siguiente
        </Button>
      </Container>
      <Modal show={show} onHide={handleClose} size='lg' centered>
        <Image src={urlActive} alt={urlActive} />
      </Modal>
    </>
  )
}
